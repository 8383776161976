import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import { theme as ourTheme } from "../theme";
import Seo from "../components/seo";
import { isDefined, isValidLength, isValidMail } from "../utils/validations";
import Mail from "../services/MailService";
import logoPositive from "../images/logo.png";

const metadata = {
  title: "Allfunds Data Analytics",
  description: "Targeting Money Market dry powder with Allfunds Navigator",
};

const ADA_SUPPORT_EMAIL = "adasales@allfunds.com";

const videoLink =
  "https://app.allfunds.com/docs/cms/a835c90bc6606c210c66e80fee27b9e791db38e5_7f4d8ec849.mp4";
const bgImg =
  "https://app.allfunds.com/docs/cms/allfunds_navigator_84c0b6d961.png";

function AllfundsDataAnalytics() {
  const { locale } = useLocalization();
  const [data, setData] = useState({
    subject: "Request ADA Navigator",
    product: "request-ada",
    type: "product",
    from: "noreply@allfunds.com",
    hasTemplate: true,
    sendTicket: false,
  });
  const [isSent, setIsSent] = React.useState(false);

  const sendRequest = () => {
    Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      if (status === 200) {
        //alert("Form Send");
        setIsSent(true);
      }
    });
  };

  const handleNameChange = (value) => {
    setData({ ...data, templateData: { ...data.templateData, name: value } });
  };

  const handleCompanyChange = (value) => {
    setData({ ...data, templateData: { ...data.templateData, company: value } });
  };

  const handleEmailChange = (value) => {
    setData({ ...data, to: [ADA_SUPPORT_EMAIL, value], templateData: { ...data.templateData, email: value } });
  };

  const validate = () => {
    const emailValid = isDefined(data?.templateData?.email) && isValidMail(data?.templateData?.email) == null;
    const nameValid =
      isDefined(data?.templateData?.name) && isValidLength(data?.templateData?.name, 3) == null;

    return emailValid && nameValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendRequest();
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  const form = () => {
    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ marginLeft: { xs: "-24px", md: 0 } }}
      >
        <Stack spacing={2}>
          <Stack spacing={2}>
            <TextField
              label={<Trans>Full name</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleNameChange(e.target.value)}
              error={isValidLength(data.name, 3)}
              helperText={isValidLength(data.name, 3)}
              value={data.name}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
            <TextField
              label={<Trans>Your Company</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleCompanyChange(e.target.value)}
              error={isValidLength(data.company, 3)}
              helperText={isValidLength(data.company, 3)}
              value={data.company}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
            <TextField
              label={<Trans>Email</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleEmailChange(e.target.value)}
              error={isValidMail(data.email)}
              helperText={isValidMail(data.email)}
              value={data.email}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
          </Stack>
          <Stack>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              sx={{
                height: "50px",
                width: "100%",
              }}
              type="submit"
              disabled={!validate()}
            >
              <Typography
                variant="info"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white !important",
                }}
              >
                <Trans>Book your demo</Trans>
              </Typography>
            </Button>
          </Stack>
          <Typography fontSize="12px" lineHeight="1.1" p={0}>
            <Trans>
              By submitting this form you agree we might contact you only for
              the purpose of following up on your interest in this topic.
            </Trans>
          </Typography>
        </Stack>
      </Box>
    );
  };

  const sendMessage = () => {
    return (
      <Stack spacing={2} textAlign="center" sx={{ marginLeft: { xs: "-24px", md: 0 } }}>
        <Typography variant="h2">
          <Trans>Thank you!</Trans>
        </Typography>
        <Typography>
          <Trans>
            If you would like further information, please don’t
            hesitate to contact us
          </Trans>
          <a href="mailto:adasales@allfunds.com"> {ADA_SUPPORT_EMAIL}</a>
        </Typography>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={ourTheme}>
      <CssBaseline />
      <StyledContainer>
        <video id="vid" autoPlay muted loop poster={bgImg}>
          <source src={videoLink} type="video/mp4" />
        </video>
        <Seo title={metadata.title} description={metadata.description} />
        <Box component="header" px={{ xs: 2, sm: 5 }} py={4} maxWidth="1200px" margin="0 auto">
          <Link className="logo" to="/">
            <img alt="allfunds_logo" src={logoPositive} />
          </Link>
        </Box>
        <Stack
          component="section"
          px={{ xs: 2, sm: 5 }}
          pb={4}
          spacing={3}
          maxWidth="1200px"
          margin="0 auto"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7} px="0!important" py="0!important">
              <Stack spacing={2}>
                <Box component="header">
                  <Typography variant="h1">
                    <Trans>Allfunds Navigator</Trans>
                  </Typography>
                  <Typography variant="h2" mt={2}>
                    <Trans>
                      The Smart Solution for Data-Driven Fund Distribution
                    </Trans>
                  </Typography>
                </Box>
                <Typography>
                  <Trans>
                    Allfunds Navigator is an advanced tool designed to transform fund distribution strategies through real-time data and AI-powered insights. Leveraging data from a universe of over €4.5tn. it provides users with actionable intelligence to uncover new distribution opportunities, identify key entry points in the market, and detect where capital is ready to move.
                  </Trans>
                </Typography>
                <Typography>
                  <Trans>
                    Whether you’re a sales team seeking immediate, focused answers or an analyst diving deep into granular details, Allfunds Navigator adapts seamlessly to your needs. With its intuitive interface, integrated virtual assistant (ANA), and customizable report generator, the tool streamlines complex data into clear, strategic guidance.
                  </Trans>
                </Typography>
                <Stack direction="column">
                  <Typography fontWeight={700}><Trans>All the Data.</Trans></Typography>
                  <Typography fontWeight={700}><Trans>All the Insight.</Trans></Typography>
                  <Typography fontWeight={700}><Trans>Are you All-in?</Trans></Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              {isSent ? sendMessage() : form()}
            </Grid>
          </Grid>
        </Stack>
      </StyledContainer>
    </ThemeProvider>
  );
}

const StyledContainer = styled(Container)(
  `
  max-width: unset!important;
  width: 100vw;
  min-height: 100dvh;
  background: linear-gradient(90deg, rgba(27,54,93,1) 0%, rgba(126,5,5,0) 100%);
  color: #fff;
  p {color: #fff;}
  #vid{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }
`,
);

export default AllfundsDataAnalytics;
