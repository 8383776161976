import { t } from "@lingui/macro";
import _ from "lodash";

export const isValidMail = (mail) => {
  const pattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return pattern.test(mail) || !mail ? null : t`Invalid mail`;
};

export const isValidLength = (text, min = 3) => {
  const validateLength = text?.length >= min;

  return validateLength || !text ? null : t`Invalid name`;
};

export const isDefined = (value) => {
  return !_.isEmpty(value) && !_.isNil(value);
};
